import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/b20595351b216cf1c1ac8bd441148951/aa440/Morne_middle-aged_tinkerer_with_dark_brown_hair_teaching_a_lect_fa3d66ab-4ce7-4b27-b185-887ac5e65b2d.png",
            "srcSet": ["/static/b20595351b216cf1c1ac8bd441148951/5ff7e/Morne_middle-aged_tinkerer_with_dark_brown_hair_teaching_a_lect_fa3d66ab-4ce7-4b27-b185-887ac5e65b2d.png 375w", "/static/b20595351b216cf1c1ac8bd441148951/1d69c/Morne_middle-aged_tinkerer_with_dark_brown_hair_teaching_a_lect_fa3d66ab-4ce7-4b27-b185-887ac5e65b2d.png 750w", "/static/b20595351b216cf1c1ac8bd441148951/aa440/Morne_middle-aged_tinkerer_with_dark_brown_hair_teaching_a_lect_fa3d66ab-4ce7-4b27-b185-887ac5e65b2d.png 1500w", "/static/b20595351b216cf1c1ac8bd441148951/71c1d/Morne_middle-aged_tinkerer_with_dark_brown_hair_teaching_a_lect_fa3d66ab-4ce7-4b27-b185-887ac5e65b2d.png 1536w"],
            "width": "550px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Lucian Cullpepper at the Basentia`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p><strong parentName="p">{`Lucian `}<em parentName="strong">{`"Luci"`}</em>{` Cullpepper`}</strong>{` is a `}<a parentName="p" {...{
        "href": "/Acronist#stonesingers",
        "title": "Acronist"
      }}>{`Stonesinger`}</a>{` and professor at the `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{`, teaching `}<a parentName="p" {...{
        "href": "/Edification",
        "title": "Edification"
      }}>{`Edification`}</a>{`. He was close colleagues with `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint`}</a>{` before his dissapearance.`}</p>
    <h2>{`Events of Enzo Quint's Dissapearance`}</h2>
    <p>{`Lucian was brought to the `}<a parentName="p" {...{
        "href": "/Saetorim%20Institute",
        "title": "Saetorim Institute"
      }}>{`Saetorim Institute`}</a>{` by `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin Fane`}</a>{` to consult on the case of Enzo's dissapearance, and Lucian has since become directly involved in the investigation, joining `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen Quint`}</a>{`, `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo Livingston`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sulazar Stillwater`}</a>{`.`}</p>
    <p>{`During the investigation, Lucian was at the `}<a parentName="p" {...{
        "href": "/Central%20Bank%20of%20Meripol",
        "title": "Central Bank of Meripol"
      }}>{`Central Bank of Meripol`}</a>{` when it was robbed by `}<a parentName="p" {...{
        "href": "/Roz%20Daras",
        "title": "Roz Daras"
      }}>{`Roz Daras`}</a>{`, and was instrumental in defeating the criminal.`}</p>
    <p>{`Lucian was also able to obtain an invitation to one of the `}<a parentName="p" {...{
        "href": "/Lauten%20Family",
        "title": "Lauten Family"
      }}>{`Lauten Family's`}</a>{` galas in order to investigate their potential involvement in Enzo's dissapearance. There, he talked personally with `}<a parentName="p" {...{
        "href": "/Rysha%20Tan%20Mei",
        "title": "Rysha Tan Mei"
      }}>{`Rysha Tan Mei`}</a>{`, the `}<a parentName="p" {...{
        "href": "/Speaker%20of%20the%20High%20Authority",
        "title": "Speaker of the High Authority"
      }}>{`Speaker of the High Authority`}</a>{` in Tharsis, where the ruins of `}<a parentName="p" {...{
        "href": "/Mehnateruna",
        "title": "Mehnateruna"
      }}>{`Mehnateruna`}</a>{` are located. who mysteriously appeared at the event. Through his conversation, he was able to determine that she was present twenty years ago when `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten's`}</a>{` thread was severed, and convinced her to become their ally in the case to provide them with further information and resources. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      